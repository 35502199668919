import React from 'react'
import { Dialog, DialogTitle, DialogContent,   Typography,Button,Badge } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

 

// const useStyles = makeStyles(theme => ({
//     dialogWrapper: {
//         padding: theme.spacing(2),
//         position: 'absolute',
//         top: theme.spacing(5)
//     },
//     dialogTitle: {
//         paddingRight: '0px'
//     }
// }))

export default function Popup(props) {

    const { title, children, openPopup, setOpenPopup ,offerListName} = props;
 
    return (
        <Dialog open={openPopup} maxWidth="md"  >
            <DialogTitle  >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    

                       
                       <Badge badgeContent={2} color="secondary">

                       <Button color="secondary">    Offer List
                       </Button>
                       </Badge>
                   
                    <Button
                        color="secondary"
                        onClick={()=>{setOpenPopup(false)}}>
                        <CloseIcon />
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}