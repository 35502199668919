import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';

// utils
import { axiosAdmin } from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  services: [],
  servicesType: [],
  service: {},
  banners: [],
};

const slice = createSlice({
  name: 'Services',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getServicesTyoeSuccess(state, action) {
      state.isLoading = false;
      state.servicesType = action.payload;
    },

    // GET PRODUCTS
    getServicesSuccess(state, action) {
      state.isLoading = false;
      state.services = action.payload;
    },

    deleteImageSuccess(state, action) {
      const { id } = action.payload;
      state.banners = state.banners.filter((img) => service.id !== id);
    },

    deleteOffersSuccess(state, action) {
      // state.isLoading = false;
      // state.offers = action.payload;
      const { id } = action.payload;
      state.offers = state.offers.filter((offer) => offer.id !== id);
    },

    // GET PRODUCT
    getServiceSuccess(state, action) {
      state.isLoading = false;
      state.service = action.payload;
    },
    createServiceSuccess(state, action) {
      const newService = action.payload;
      state.isLoading = false;
      state.services = [...state.services, newService];
    },

    // CHECKOUT

    deleteOfferSuccess(state, action) {
      //     const { id } = action.payload;

      //  state.offers =  state.offers.filter((offer) => offer.id !== id);
      state.isLoading = false;
      state.offers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getServiceSuccess, getServicesSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function createService(newService) {
  console.log(newService);

  if (newService.radioValue === 'manualentry') {
    console.log('first');
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const newServices = new FormData();
          const images = newService.banners;

          for (let i = 0; i < images.length; i++) {
            newServices.append('images', images[i]);
          }

          newServices.append('title', newService.title);
          newServices.append('description', newService.description);
          newServices.append('price', newService.price ? newService.price : 0);
          newServices.append('storeId', newService.storeId);
          newServices.append('serviceType', newService.serviceType);
          newServices.append('serviceTypeId', newService.serviceTypeId);
          console.log(newServices);
          const response = await axiosAdmin.post('/services', newServices);
          console.log(response);
          dispatch(slice.actions.createServiceSuccess(response.data));
        }
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  } else if (newService.radioValue === 'uploadpdf') {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const newServices = new FormData();
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          newServices.append('title', newService.title);
          newServices.append('storeId', newService.storeId);
          newServices.append('serviceTypeId', newService.serviceTypeId);
          newServices.append('serviceType', newService.serviceType);

          newServices.append('pdf', newService.pdfFile);
          
          const response = await axiosAdmin.post('/services', newServices);
          console.log(response);
          dispatch(slice.actions.createServiceSuccess(response.data));
        }
      } catch (error) {
        console.log(error)
        dispatch(slice.actions.hasError(error));
      }
    };
  }
}

export function getServices(storeId) {
  console.log(storeId)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get('/services', {
          params: { storeId },
        });
console.log(response)
        dispatch(slice.actions.getServicesSuccess(response.data));

        // alert(JSON.stringify(response.data))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getService(id) {
  console.log(id)
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axiosAdmin.get(`/services/${id}`);
      console.log(response)
      dispatch(slice.actions.getServiceSuccess(response.data));
    } catch (error) {
      //console.error('error' +JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateService(id, newService) {
  console.log(newService);
  console.log(id);
  // return async () => {
  //   dispatch(slice.actions.startLoading());
  //   try {
  //     // const accessToken = window.localStorage.getItem('accessToken');
  //     // if (accessToken && isValidToken(accessToken)) {
  //     //   setSession(accessToken);
  //     //   const response = await axiosAdmin.put(`/services/${id}`, updateOffer);
  //     //   console.log(response);
  //     //   // dispatch(slice.actions.getOfferSuccess(response.data));
  //     // }

  //         const accessToken = window.localStorage.getItem('accessToken');
  //         const newServices = new FormData();

  //         if (accessToken && isValidToken(accessToken)) {
  //           setSession(accessToken);

  //           newServices.append('title', updateOffer.title);
  //           newServices.append('storeId', updateOffer.storeId);
  //           newServices.append('serviceTypeId', updateOffer.serviceTypeId);
  //           newServices.append('pdfFile', updateOffer.pdfFile);
  //           const response = await axiosAdmin.put(`/services/${id}`, newServices);
  //           console.log(response);
  //           dispatch(slice.actions.createServiceSuccess(response.data));
  //         }

  //     }

  //   catch (error) {
  //     console.log(error);
  //     dispatch(slice.actions.hasError(error));
  //   }
  // };
  if (newService.radioValue === 'manualentry'|| newService.defaultRadioValue==='manualentry') {
    console.log('first');
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const newServices = new FormData();
          const images = newService.banners;

          for (let i = 0; i < images.length; i++) {
            newServices.append('images', images[i]);
          }

          newServices.append('title', newService.title);
          newServices.append('description', newService.description);
          newServices.append('price', newService.price ? newService.price : 0);
          newServices.append('storeId', newService.storeId);
          newServices.append('serviceTypeId', newService.serviceTypeId);
          console.log(newServices);
          const response = await axiosAdmin.put(`/services/${id}`, newServices);
          console.log(response);
          dispatch(slice.actions.createServiceSuccess(response.data));
        }
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  } else if (newService.radioValue === 'uploadpdf' || newService.defaultRadioValue==='uploadpdf') {
    console.log("first")
    console.log(newService)
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());

      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const newServices = new FormData();
console.log("first")
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
console.log("first")
          newServices.append('title', newService.title);
          newServices.append('storeId', newService.storeId);
          newServices.append('serviceTypeId', newService.serviceTypeId);
          newServices.append('pdf', newService.pdfFile);
          const response = await axiosAdmin.put(`/services/${id}`, newServices);
          console.log(response);
          dispatch(slice.actions.createServiceSuccess(response.data));
        }
      } catch (error) {
        console.log(error)
        dispatch(slice.actions.hasError(error));
      }
    };
  }
}
// ----------------------------------------------------------------------

export function deleteService(offerId) {
  console.log(offerId);

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.delete(`/services/${offerId}`);
      // dispatch(slice.actions.deleteOfferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePdfService(offerId) {
  console.log(offerId);

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.delete(`/services/remove-pdf/${offerId}`);
      // dispatch(slice.actions.deleteOfferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




export function deleteManyService(serviceId) {
  // alert(offerId)
  console.log(serviceId)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.post('/services/remove-many', serviceId);
      // dispatch(slice.actions.deleteOfferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteServiceImage(imgId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.delete(`/services/remove-image/${imgId}`);

        dispatch(slice.actions.deleteImageSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
