import { format, getTime, formatDistanceToNow,parseISO } from 'date-fns';

// ----------------------------------------------------------------------

export function convertDate(date) {
  const dateAndTime = date.toISOString().split('T')
  const time = dateAndTime[1].split(':');
 
  
  return dateAndTime[0]+'T'+time[0]+':'+time[1]
}
// export function convertDate(date) {
//   const dateObj = parseISO(date);
//  return format(new Date(dateObj), 'yyyy-MM-dd HH:mm').toString();
//   //  return format(parseISO(date), "yyyy-LL-dd HH:mm") 

// }
export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
