import PropTypes from 'prop-types';
/* eslint-disable */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
  Chip,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Select,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import Page from '../../../components/Page';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useDispatch } from '../../../redux/store';
import useAuth from '../../../hooks/useAuth';
import { createBooking } from 'src/redux/slices/booking';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';

SingleView.propTypes = {
  isEdit: PropTypes.bool,
  currentCategory: PropTypes.object,
};
export default function SingleView({ isEdit, currentCategory }) {
  const dispatch = useDispatch();
  const NewProductSchema = Yup.object().shape({
    // name: Yup.string().required('Name is required'),
    // description: Yup.string().required('Description is required'),
    // images: Yup.string().min(1, 'Images is required'),
    // tags: Yup.number().moreThan(0, 'Price should not be $0.00'),
  });

  const defaultValues = useMemo(
    () => ({
      name: (isEdit && currentCategory?.name) || '',
      description: (isEdit && currentCategory?.description) || '',
      images: (isEdit && currentCategory?.categoryImage?.location) || '',
      tags: (isEdit && currentCategory?.tags) || [],
      priority: (isEdit && currentCategory?.priority) || 0,
      name2: (isEdit && currentCategory?.name2) || '',
      userid: (isEdit && currentCategory?.userid) || '',
      userName: (isEdit && currentCategory?.userName) || '',
      email: (isEdit && currentCategory?.email) || '',
      dateofBooking: (isEdit && currentCategory?.dateofBooking) || '',
      noofTables: (isEdit && currentCategory?.noofTables) || '',
      noofguest: (isEdit && currentCategory?.noofguest) || '',
      timeSlot: (isEdit && currentCategory?.timeSlot) || '',
      phone: (isEdit && currentCategory?.phone) || '',
      cutlerySuggestion: (isEdit && currentCategory?.cutlerySuggestion) || '',
      message: (isEdit && currentCategory?.message) || '',
    }),
    [currentCategory]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentCategory) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentCategory]);

  //edit option

  const initialData = {
    userName: 'vyxyte',
    email: 'gutica@mailinator.com',
    phone: '+1 (149) 961-4976',
    guestNo: 'Quam sit sunt volup',
    selectedDate: new Date('2023-10-19T18:30:00.000Z'),
    fromTime: new Date('2023-10-15T21:30:00.000Z'),
    toTime: new Date('2023-10-15T22:30:00.000Z'),
    specialRequest: 'Qui pariatur Corrup',
    offers: 'Quod cillum dolore s',
    note: 'Labore assumenda vol',
    selectedOptions: ['Birthday'],
    tables: [
      {
        id: '13',
        tableNumber: 27,
        seatingCapacity: 2,
        createdAt: '2023-10-03T06:45:16.793Z',
        createdBy: '1',
        updatedAt: '2023-10-03T06:45:16.793Z',
        updatedBy: null,
        deletedAt: null,
        deletedBy: null,
      },
      {
        id: '20',
        tableNumber: 19,
        seatingCapacity: 3,
        createdAt: '2023-10-03T06:45:28.378Z',
        createdBy: '1',
        updatedAt: '2023-10-03T06:45:28.378Z',
        updatedBy: null,
        deletedAt: null,
        deletedBy: null,
      },
    ],
  };

  const [formData, setFormData] = useState(initialData);

  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  //tables
  const [selectedTables, setSelectedTables] = useState(initialData.tables.map(table => table.id));
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [status, setStatus] = React.useState('');

  const selectedDDate = selectedDate ? selectedDate : initialData?initialData.selectedDate:" ";

  const timeRangesArray = [
    {
      id: 1,
      tables: [
        {
          id: '13',
          tableNumber: 6,
          seatingCapacity: 2,
          createdAt: '2023-10-03T06:45:08.718Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:08.718Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
        {
          id: '14',
          tableNumber: 1,
          seatingCapacity: 2,
          createdAt: '2023-10-03T06:45:16.793Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:16.793Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
        {
          id: '15',
          tableNumber: 2,
          seatingCapacity: 7,
          createdAt: '2023-10-03T06:45:28.378Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:28.378Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
      ],
      startDate: new Date('2023-10-03T18:30:00.000Z'),
      endDate: new Date('2023-10-10T18:30:00.000Z'),
      startTime: new Date('Mon Oct 09 2023 03:00:00 GMT+0530 (India Standard Time)'),
      endTime: new Date('Mon Oct 09 2023 18:00:00 GMT+0530 (India Standard Time)'),
    },
    {
      id: 2,
      tables: [
        {
          id: '16',
          tableNumber: 16,
          seatingCapacity: 6,
          createdAt: '2023-10-03T06:45:08.718Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:08.718Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
        {
          id: '18',
          tableNumber: 17,
          seatingCapacity: 2,
          createdAt: '2023-10-03T06:45:16.793Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:16.793Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
        {
          id: '19',
          tableNumber: 27,
          seatingCapacity: 2,
          createdAt: '2023-10-03T06:45:16.793Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:16.793Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
        {
          id: '28',
          tableNumber: 17,
          seatingCapacity: 2,
          createdAt: '2023-10-03T06:45:16.793Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:16.793Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
        {
          id: '20',
          tableNumber: 19,
          seatingCapacity: 3,
          createdAt: '2023-10-03T06:45:28.378Z',
          createdBy: '1',
          updatedAt: '2023-10-03T06:45:28.378Z',
          updatedBy: null,
          deletedAt: null,
          deletedBy: null,
        },
      ],
      startDate: new Date('2023-10-13T18:30:00.000Z'),
      endDate: new Date('2023-10-23T18:30:00.000Z'),
      startTime: new Date('Mon Oct 09 2023 08:00:00 GMT+0530 (India Standard Time)'),
      endTime: new Date('Mon Oct 09 2023 12:00:00 GMT+0530 (India Standard Time)'),
    },
    // Add more time ranges as needed
  ];

  const groupedTables = {};

  // Group tables by seating capacity and filter by selected date
  timeRangesArray.forEach((timeRange) => {
    if (selectedDDate >= timeRange.startDate && selectedDDate <= timeRange.endDate) {
      timeRange.tables.forEach((table) => {
        const seatingCapacity = table.seatingCapacity;

        if (!groupedTables[seatingCapacity]) {
          groupedTables[seatingCapacity] = [];
        }

        groupedTables[seatingCapacity].push(table);
      });
    }
  });

  const handleTableClick = (tableId) => {
    if (selectedTables.includes(tableId)) {
      setSelectedTables(selectedTables.filter((id) => id !== tableId));
    } else {
      console.log(tableId)
    setSelectedTables([...selectedTables, tableId]);
    }
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleFromTimeChange = (newFromTime) => {
    setFromTime(newFromTime);

    // Calculate the minimum allowed "To Time" as the selected "From Time"
    const minToTime = new Date(newFromTime);

    // Calculate the maximum allowed "To Time" as 2 hours ahead of the selected "From Time"
    const maxToTime = new Date(newFromTime);
    maxToTime.setHours(maxToTime.getHours() + 2);

    // If the current "To Time" is earlier than the minimum or later than the maximum, reset it
    if (toTime && (toTime < minToTime || toTime > maxToTime)) {
      setToTime(null);
    }
  };

  const handleToTimeChange = (newTime) => {
    setToTime(newTime);
  };

  const options = ['Birthday', 'Anniversary', 'Date', 'Special Occasion', 'Businesss Meal'];

  const chipStyle = {
    backgroundColor: '#fff',
    color: 'black',
    border: '2px solid #888',
    borderRadius: '16px',
    fontSize: '14px',
  };

  const removeIconStyle = {
    cursor: 'pointer',
    marginLeft: '8px',
  };

  const tagInputStyle = {
    fontSize: '14px',
  };

  const optionStyle = {
    backgroundColor: '#e0e0e0',
    padding: '8px 16px',
    borderRadius: '16px',
    cursor: 'pointer',
    fontSize: '14px',
  };

  const submitButtonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '16px',
    marginTop: '16px',
  };

  const tagsContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    alignItems: 'center',
    width: '50%',
    border: '1px solid #888',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '16px',
  };

  const [selectedOptions, setSelectedOptions] = useState([initialData.selectedOptions]);
  const [inputValue, setInputValue] = useState('');
  const [showCustom, setShowCustom] = useState(false);

  const handleSelect = (option) => {
    if (inputValue && selectedOptions.length === 1) {
      setSelectedOptions([inputValue]);
      setInputValue('');
    } else {
      setSelectedOptions([option]);
      setInputValue('');
    }
  };

  const handleRemove = () => {
    setSelectedOptions([]);
  };

  const onSubmit = (data) => {
    dispatch(createBooking(data));
    console.log(data);

    console.log('selectedOptions', selectedOptions);
    const formattedTables = selectedTables.map((tableId) => {
      const selectedTable = timeRangesArray
        .map((timeRange) => timeRange.tables.find((table) => table.id === tableId))
        .filter(Boolean)[0];

      if (selectedTable) {
        return {
          id: selectedTable.id,
          tableNumber: selectedTable.tableNumber,
          seatingCapacity: selectedTable.seatingCapacity,
          createdAt: selectedTable.createdAt,
          createdBy: selectedTable.createdBy,
          updatedAt: selectedTable.updatedAt,
          updatedBy: selectedTable.updatedBy,
          deletedAt: selectedTable.deletedAt,
          deletedBy: selectedTable.deletedBy,
        };
      }

      return null;
    });
    const m = {
      userName: data ? data.userName : ' ',
      email: data ? data.email : ' ',
      phone: data ? data.phone : ' ',
      guestNo: data ? data.guestNo : ' ',
      specialRequest: data ? data.specialRequest : ' ',
      offers: data ? data.offers : ' ',
      note: data ? data.note : ' ',
      selectedOptions: selectedOptions ? selectedOptions : ' ',
      tables: formattedTables ? formattedTables.filter(Boolean) : ' ',
    };

    console.log(m);
  };

  return (
    <Page title="Booking List">
      <HeaderBreadcrumbs
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to={PATH_DASHBOARD.category.newCategory}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            Create New
          </Button>
        }
        heading="Edit Booking "
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          {
            name: 'Store',
            href: PATH_DASHBOARD.category.root,
          },
          { name: 'Booking List' },
        ]}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <RHFTextField
              name="phone"
              label="Phone Number"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFTextField
              name="userName"
              label="User Name "
              value={formData.userName}
              onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFTextField
              name="email"
              label="Email id"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFTextField
              name="guestNo"
              label="No of Guests"
              value={formData.guestNo}
              onChange={(e) => setFormData({ ...formData, guestNo: e.target.value })}
              disabled={!editMode}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <DatePicker
              label="Select Booking Date"
              value={formData.selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} sx={{ gridColumn: 'span 12' }} />}
              slotProps={{ textField: { fullWidth: true } }}
              disabled={!editMode}

            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimePicker
              label="From Time"
              value={formData.fromTime}
              onChange={handleFromTimeChange}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{ textField: { fullWidth: true } }}
              disabled={!editMode}

            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TimePicker
              label="To Time"
              value={formData.toTime}
              onChange={handleToTimeChange}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{ textField: { fullWidth: true } }}
              // Set minTime to the selected "From Time" and maxTime to 2 hours ahead of it
              minTime={fromTime || null}
              maxTime={fromTime ? new Date(fromTime.getTime() + 2 * 60 * 60 * 1000) : null}
                            disabled={!editMode}

            />
          </Grid>

          <Grid item xs={12} md={8} marginBottom={3} marginTop={3}>
            <Typography variant="h6">Whats's the occassion ?</Typography>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
              {selectedOptions.length > 0 && (
                <div style={tagsContainerStyle}>
                  <Chip
                    label={selectedOptions[0]}
                    style={chipStyle}
                    onDelete={handleRemove}
                    deleteIcon={<span style={removeIconStyle}>&times;</span>}
                  />
                </div>
              )}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '4px' }}>
                {options.map((option) => (
                  <div key={option} onClick={() => handleSelect(option)} style={optionStyle}>
                    {option}
                  </div>
                ))}
              </div>
              <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <Button
                  style={{ marginTop: '10px', backgroundColor: 'whitesmoke' }}
                  onClick={() => setShowCustom(!showCustom)}
                >
                  Add custom option
                </Button>
                {showCustom && (
                  <TextField
                    variant="outlined"
                    value={inputValue}
                    label="enter a custom tag"
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        if (inputValue && selectedOptions.length === 1) {
                          setSelectedOptions([inputValue]);
                          setInputValue('');
                        } else if (inputValue) {
                          handleSelect(inputValue);
                        }
                      }
                    }}
                    style={tagInputStyle}
                  />
                )}
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Select Tables:</Typography>
            <Grid container spacing={2}>
              {Object.keys(groupedTables).map((seatingCapacity) => (
                <Grid item xs={12} sm={6} key={seatingCapacity}>
                  <Paper elevation={7} style={{ padding: '10px' }}>
                    <Typography align="center" variant="h5">
                      {seatingCapacity} seating capacity
                    </Typography>
                    <List>
                      <Grid container spacing={1}>
                        {groupedTables[seatingCapacity].map((table, index) => (
                          <Grid item xs={6} md={3} key={table.id}>
                            <ListItem
                              onClick={() => handleTableClick(table.id)}
                              style={{
                                cursor: 'pointer',
                                backgroundColor: selectedTables.includes(table.id) ? 'blue' : 'transparent',
                                color: selectedTables.includes(table.id) ? 'white' : 'black',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Add shadow
                                borderRadius: '8px', // Add rounded corners
                                padding: '12px', // Add padding
                                marginBottom: '8px', // Add margin bottom for spacing between items
                              }}
                            >
                              <ListItemText primary={`Table ${index + 1}`} />
                            </ListItem>
                          </Grid>
                        ))}
                      </Grid>
                    </List>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <RHFTextField name="specialRequest" label="Special Request" />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFTextField name="offers" label="Offers and coupons" />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: '18px', color: 'grey' }} // Apply inline styles
              >
                Select Booking Method
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Select Booking MethodMethod"
                onChange={handleChange}
              >
                <MenuItem value="item1">Walk-in</MenuItem>
                <MenuItem value="item2">Telephonic</MenuItem>
                <MenuItem value="item3">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RHFTextField name="note" label="Add Note" multiline rows={5} rowsMax={20} />
          </Grid>

          <Grid item xs={12}>
            {editMode ? (
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Save Changes
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={toggleEditMode} fullWidth>
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </Page>
  );
}
