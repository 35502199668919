import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// utils
import {axiosAdmin} from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  serviceTypes:[],
  servicesType: [],

  serviceTypeAll:[],
    serviceType:  {},
    filters: {
      name: [],
     
    },
    selectedCatId: null,
    count: null,
    pages: null,
    skip:null
//   sortBy: null,
//   filters: {
//     gender: [],
//     ServiceType: 'All',
//     colors: [],
//     priceRange: '',
//     rating: '',
//   },
//   checkout: {
//     activeStep: 0,
//     cart: [],
//     subtotal: 0,
//     total: 0,
//     discount: 0,
//     shipping: 0,
//     billing: null,
//   },
};

const slice = createSlice({
  name: 'ServiceType',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getServicesSuccess(state, action) {
      state.isLoading = false;
      state.servicesType = action.payload.list;
      state.count = action.payload.count;
      state.pages = action.payload.page;
      state.skip = action.payload.skip;
    },

    getServicesSuccessAll(state, action) {
      state.isLoading = false;
      state.serviceTypeAll = action.payload;
     // alert('data' +JSON.stringify(action.payload))
      
    },

    deleteCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.servicesType = action.payload;
    },

    // GET PRODUCT
    getServiceTypeSuccess(state, action) {
      state.isLoading = false;
      state.serviceType = action.payload;
    },

 
    filterServiceType(state, action) {
    //  state.filters.name = action.payload.name;
    state.categories = action.payload;
    },
   
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    // CHECKOUT
     
    deleteServiceTypeSuccess(state, action) {
      const { id } = action.payload;
   state.categories =  state.categories.filter((ServiceType) => ServiceType.id !== id);
      

      
    },
 
    
    selectServiceType(state, action) {
      const catId = action.payload;
      state.isOpenModal = true;
      state.selectedCatId = catId;
    },
    

 
  

  
    createServiceTypeSuccess(state, action) {
      const newServiceType = action.payload;
      state.isLoading = false;
      state.servicesType = [...state.servicesType, newServiceType];
    },
 

   

   

  
     
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
 getCategoriesSuccess,
 selectServiceType,
 filterServiceType,sortByProducts
} = slice.actions;

// ----------------------------------------------------------------------
 

export function createServiceType(newServiceType) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
      const response = await axiosAdmin.post('/service-type', newServiceType);
   
      dispatch(slice.actions.createServiceTypeSuccess(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error))
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function getCategories() {
//   return async () => {

//     alert('ServiceType')
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
 
        
      
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);

//       const response = await axiosAdmin.get('/categories');
    
//       dispatch(slice.actions.getCategoriesSuccess(response.data));
//       }
        
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
 

export function getServicesType(params) {
  return async () => {
 
    
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.get('/service-type?',{
        params
      });
    
      dispatch(slice.actions.getServicesSuccess(response.data));
    
  
      }
        
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getServicesTypeAll(params) {
  return async () => {
 
    
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.get('/service-type?',{
        params
      });
    
      dispatch(slice.actions.getServicesSuccessAll(response.data));
    
  
      }
        
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getServiceTypeById(id) {

 
  return async () => {
    dispatch(slice.actions.startLoading());

 
    
    try {
      const accessToken = window.localStorage.getItem('accessToken');
 
        
      
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.get(`/service-type/${id}`);
      dispatch(slice.actions.getServiceTypeSuccess(response.data));

     // alert(JSON.stringify(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error));
     // console.error('error' +JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };

}
export function updatServiceType(id, updateServiceType) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

      const response = await axiosAdmin.put(`/service-type/${id}`,updateServiceType);
      dispatch(slice.actions.getCategoriesSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

 
export function deleteServiceType(ServiceTypeID) {

 // alert(ServiceTypeID)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
     if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

    const response =   await axiosAdmin.delete(`/service-type/${ServiceTypeID}`);
      dispatch(slice.actions.deleteCategoriesSuccess(response.data));
     }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function deleteManyServices(ServiceTypeID) {

  // alert(offerId)
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
      const response =   await axiosAdmin.post('/service-type/remove-many',ServiceTypeID);
    
        dispatch(slice.actions.getServiceTypeSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }