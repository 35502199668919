// import { capitalCase } from 'change-case';
// import { Link as RouterLink } from 'react-router-dom';
// // @mui
// import { styled } from '@mui/material/styles';
// import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// // routes
// import { PATH_AUTH } from '../../routes/paths';
// // hooks
// import useAuth from '../../hooks/useAuth';
// import useResponsive from '../../hooks/useResponsive';
// // components
// import Page from '../../components/Page';
// import Logo from '../../components/Logo';
// import Image from '../../components/Image';
// // sections
// import { LoginForm } from '../../sections/auth/login';

// // ----------------------------------------------------------------------

// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

// const HeaderStyle = styled('header')(({ theme }) => ({
//   top: 0,
//   zIndex: 9,
//   lineHeight: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   position: 'absolute',
//   padding: theme.spacing(3),
//   justifyContent: 'space-between',
//   [theme.breakpoints.up('md')]: {
//     alignItems: 'flex-start',
//     padding: theme.spacing(7, 5, 0, 7),
//   },
// }));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2),
// }));

// const ContentStyle = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   display: 'flex',
//   minHeight: '100vh',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: theme.spacing(12, 0),
// }));

// // ----------------------------------------------------------------------

// export default function Login() {
//   const { method } = useAuth();

//   const smUp = useResponsive('up', 'sm');

//   const mdUp = useResponsive('up', 'md');

//   return (
//     <Page title="Login">
//       <RootStyle>

//         {mdUp && (
//           <SectionStyle>
//             <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
//               Hi, Welcome Back
//             </Typography>
//             <Image
//               alt="login"
//               src="logo/main-logo.png"
//             />

//           </SectionStyle>
//         )}

//         <Container maxWidth="sm">
//           <ContentStyle>
//             <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
//               <Box sx={{ flexGrow: 1 }}>
//                 <Typography variant="h4" gutterBottom>
//                   ALHUB DASHBOARD
//                 </Typography>
//                 <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
//               </Box>

//             </Stack>

//             <LoginForm />

//             {/* {!smUp && (
//               <Typography variant="body2" align="center" sx={{ mt: 3 }}>
//                 Don’t have an account?{' '}
//                 <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
//                   Get started
//                 </Link>
//               </Typography>
//             )} */}
//           </ContentStyle>
//         </Container>
//       </RootStyle>
//     </Page>
//   );
// }

import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import { template } from 'lodash';
import '../../../src/app.css';

// ----------------------------------------------------------------------
const ColumnsStyle = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)', // Adjust the number of columns as needed
  overflow: 'hidden',
}));

const ColumnStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  animation: 'move-columns linear infinite',
  animationDuration: '15s', // Adjust the duration as needed
  animationFillMode: 'forwards',
}));

const ColumnReverseStyle = styled(ColumnStyle)({
  animationName: 'move-columns-reverse',
});

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 600,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const CarouselImage = styled(Image)(({ theme }) => ({
  maxWidth: '100%',
  margin: '2px',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const s3_url = 'https://alhub-web-images.s3.ap-south-1.amazonaws.com/'
  return (
    // <Page title="Login">

    //         <LoginForm />

    // </Page>
    <div className=''>
      <div className="maketwo desktop-only">
        <div style={{ border: '2px' }} className="blue flex flex-col">
          <div className='flex flex-col justify-center items-center'>
            <img
            style={{ width: '130px' }} 
              src={`${s3_url}login-screen/logo-3+1.svg`}
              alt="Image 3"
            />{' '}
            <h1 style={{ marginTop: "10px", marginBottom: "10px" }} >Welcome Back</h1>
          </div>
          <div className="w-full">
            <LoginForm />
          </div>
        </div>

        <div className="three">
          <div>
            <div className="marquee-container ml-120">
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 20.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 21.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 22.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 23.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="marquee-container ">
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 24.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 24.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 25.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 26.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="marquee-container">
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 27.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 28.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 29.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src={`${s3_url}login-screen/Rectangle 30.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="marquee-container">
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 31.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 32.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 33.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src={`${s3_url}login-screen/Rectangle 34.png`}
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-screen">
        <div style={{ border: '2px' }} className="blue flex flex-col">
          <div className='flex flex-col justify-center items-center'>
            <img
                  src={`${s3_url}login-screen/Rectangle 35.png`}
                  alt="Image 3"
              style={{}}
            />{' '}
            <h1 style={{ marginTop: "10px", marginBottom: "10px" }} >Welcome Back</h1>
          </div>
          <div className="">
            <LoginForm />
          </div>
        </div>

        {/* <div className="three">
          <div>
            <div className="marquee-container ml-120">
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '350px' }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="marquee-container ">
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="marquee-container">
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marquee">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="marquee-container">
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
              <div className="marq-top">
                <img
                  src="https://res.cloudinary.com/https-www-lymdata-com/image/upload/v1694670103/LYMDATALABS/Alhub/login/3_aicjqj.jpg"
                  className="radius_2xl"
                  alt="Image 3"
                  style={{ height: '340px', width: '250px' }}
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
