import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { fDate,fDateTime,fDateTimeSuffix,convertDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------
import { format, getTime, formatDistanceToNow,parseISO } from 'date-fns';
RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({  field, fieldState: { error } }) => (
        
        <DateTimePicker
     
        {...field} fullWidth {...other}
        renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message}  />}
      />
      )}
    />
  );
}
