import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import categoryReducer from './slices/category';
import offerReducer from './slices/offer';
import storeReducer from './slices/store';
import serviceTypeReducer from './slices/serviceType';
import serviceReducer from './slices/service';
import bookingReducer from './slices/booking';
import tableReducer from './slices/table';
import tableSlotReducer from './slices/tableSlot';
import slotSlot from './slices/slotSlot';
import slotBooking from './slices/slotBooking';
import adminRegisterReducer from './slices/adminRegister';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  category: persistReducer(productPersistConfig, categoryReducer),
  //table section
  table: persistReducer(productPersistConfig, tableReducer),
  tableSlotReducer: persistReducer(productPersistConfig, tableSlotReducer),
  booking: persistReducer(productPersistConfig, bookingReducer),
  //slot section
  slotSlot: persistReducer(productPersistConfig, slotSlot),
  slotBooking:persistReducer(productPersistConfig,slotBooking ),
  store: storeReducer,
  adminReducer: persistReducer(productPersistConfig, adminRegisterReducer),
  offer: persistReducer(productPersistConfig, offerReducer),
  serviceType: persistReducer(productPersistConfig, serviceTypeReducer),
  service: persistReducer(productPersistConfig, serviceReducer),
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
