import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';

// utils
import { axiosAdmin } from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  offers: [],
  offer: {},
};

const slice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getOffersSuccess(state, action) {
      state.isLoading = false;
      state.offers = action.payload;
    },

    deleteOffersSuccess(state, action) {
      // state.isLoading = false;
      // state.offers = action.payload;
      const { id } = action.payload;
      state.offers = state.offers.filter((offer) => offer.id !== id);
    },

    // GET PRODUCT
    getOfferSuccess(state, action) {
      state.isLoading = false;
      state.offer = action.payload;
    },
    createOfferSuccess(state, action) {
      const newOffer = action.payload;
      state.isLoading = false;
      state.offers = [...state.offers, newOffer];
    },

    // CHECKOUT

    deleteOfferSuccess(state, action) {
      //     const { id } = action.payload;

      //  state.offers =  state.offers.filter((offer) => offer.id !== id);
      state.isLoading = false;
      state.offers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getOfferSuccess, getOffersSuccess } = slice.actions;

// ----------------------------------------------------------------------

// export function createOffer(newOffer, callBack) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         console.log(newOffer);
//         const response = await axiosAdmin.post('/offers', newOffer);
//         callBack(false);
//         console.log(response);
//         dispatch(slice.actions.createOfferSuccess(response.data));
//       }
//     } catch (error) {
//       callBack(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }



// export function createOffer(newStore, callBack) {
//   return async () => {
//     console.log(newStore)
//     dispatch(slice.actions.startLoading());
//   //   try {
//   //     const accessToken = window.localStorage.getItem('accessToken');

//   //     if (accessToken && isValidToken(accessToken)) {
//   //       setSession(accessToken);
//   //       let storeArray = new FormData();
//   //       const images = newStore?.banners;

//   //       for (let i = 0; i < images.length; i++) {
//   //         storeArray.append('banners', images[i]);
//   //       }

//   //       storeArray.append('tags', JSON.stringify(newStore?.tags));
//   //       storeArray.append('contacts', JSON.stringify(newStore?.contacts));
//   //       storeArray.append('features', JSON.stringify(newStore?.features));
//   //       storeArray.append('socialLinks', JSON.stringify(newStore?.socialLinks));
//   //       storeArray.append('workingHours', JSON.stringify(newStore?.workingHours));
//   //       storeArray.append('name', newStore?.name);
//   //       storeArray.append('description', newStore?.description);
//   //       storeArray.append('image', newStore?.logo);
//   //       storeArray.append('categoryId', newStore?.categoryId);
//   //       storeArray.append('location', newStore?.location);
//   //       storeArray.append('latitude', newStore?.latitude);
//   //       storeArray.append('whatsappNumber', newStore?.whatsappNumber);
//   //       newStore?.options && storeArray.append('option', newStore?.options);
//   //       storeArray.append('longitude', newStore?.longitude);
//   //       storeArray.append('offerPIN', newStore?.offerPIN);
//   //       storeArray.append('primaryEmail', newStore?.primaryEmail);

//   //       const response = await axiosAdmin.post('/stores', storeArray, {
//   //         headers: {
//   //           'Content-Type': 'multipart/form-data',
//   //         },
//   //       });
//   //       callBack(false);
//   //       dispatch(slice.actions.createStoreSuccess(response.data));
//   //     }
//   //   } catch (error) {
//   //     // console.log('error::::::::: ', error);
//   //     callBack(error);
//   //     dispatch(slice.actions.hasError(error));
//   //   }
//   };
// }


export function createOffer(newStore, callBack) {
  return async (dispatch) => {
    console.log(newStore);
    // dispatch(slice.actions.startLoading());

    // Create a FormData object
    const formData = new FormData();

    // Convert dates to ISO 8601 strings and ensure the boolean field is a boolean
    formData.append('startsAt', new Date(newStore.startsAt).toISOString());
    formData.append('endsAt', new Date(newStore.endsAt).toISOString());
    formData.append('redemptionStartsAt', new Date(newStore.redemptionStartsAt).toISOString());
    formData.append('redemptionEndsAt', new Date(newStore.redemptionEndsAt).toISOString());
    formData.append('isFeatured', newStore.isFeatured);

    // Append all other fields except the images
    for (const key in newStore) {
      if (!['startsAt', 'endsAt', 'redemptionStartsAt','isFeatured', 'redemptionEndsAt', 'images'].includes(key)) {
        formData.append(key, newStore[key]);
      }
    }

    // Append the image file(s)
    if (newStore.images) {
      formData.append('images', newStore.images);
    }

    try {
      // Send the form data using axios
      const response = await axiosAdmin.post('/offers', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response)
     callBack(false);

      // Call the callback function if provided
    

      // dispatch(slice.actions.stopLoading());
    } catch (error) {
      callBack(error);

      console.error('Error creating offer:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOffers(storeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get('/offers', {
          params: { storeId },
        });
        console.log(response);
        dispatch(slice.actions.getOffersSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOffer(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.get(`/offers/${id}`);
      dispatch(slice.actions.getOfferSuccess(response.data));
      //  alert(response.data)
      console.log('getofffer', response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateOffer(id,newStore,callBack) {
  console.log(id)
  return async (dispatch) => {
    console.log(newStore);
    // dispatch(slice.actions.startLoading());

    // Create a FormData object
    const formData = new FormData();

    // Convert dates to ISO 8601 strings and ensure the boolean field is a boolean
    formData.append('startsAt', new Date(newStore.startsAt).toISOString());
    formData.append('endsAt', new Date(newStore.endsAt).toISOString());
    formData.append('redemptionStartsAt', new Date(newStore.redemptionStartsAt).toISOString());
    formData.append('redemptionEndsAt', new Date(newStore.redemptionEndsAt).toISOString());
    formData.append('isFeatured', newStore.isFeatured);

    // Append all other fields except the images
    for (const key in newStore) {
      if (!['startsAt', 'endsAt', 'redemptionStartsAt','isFeatured', 'redemptionEndsAt', 'images'].includes(key)) {
        formData.append(key, newStore[key]);
      }
    }

    // Append the image file(s)
    if (newStore.images) {
      formData.append('images', newStore.images);
    }

    try {
      // Send the form data using axios

      const response = await axiosAdmin.put(`/offers/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response)
     callBack(false);

      // Call the callback function if provided
    

      // dispatch(slice.actions.stopLoading());
    } catch (error) {
      callBack(error);

      console.error('Error creating offer:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteOffer(offerId) {
  console.log(offerId);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.delete(`/offers/${offerId}`);

      // dispatch(slice.actions.deleteOfferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteManyOffer(offerId) {
  console.log('first');
  // alert(offerId)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.post('/offers/remove-many', offerId);
      console.log(response);
      // dispatch(slice.actions.deleteOfferSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
