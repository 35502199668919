import { sentenceCase } from 'change-case';
import * as React from 'react';
/* eslint-disable */
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Button,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Container,
  FormControl,
  TableContainer,
  TablePagination,
  Alert,
  TableHead,
  Paper,
  TextField,
  IconButton,
  TableSortLabel,
  Grid,
  Badge,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getCategories, deleteCategory, deleteManyCategory } from '../../../redux/slices/category';
// utils
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Image from '../../../components/Image';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import swal from 'sweetalert';

// sections
import {
  CategoryMoreMenu,
  CategoryListHead,
  CategoryListToolbar,
} from '../../../sections/@dashboard/category/category-list/index';
import BookingListMoreMenu from 'src/sections/@dashboard/booking/booking-list/BookingListMoreMenu';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getSlotBooking } from 'src/redux/slices/slotBooking';
import { getTableBooking } from 'src/redux/slices/booking';

export default function SlotBookingHistory() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { categories, count } = useSelector((state) => state.category);
  const [categoryList, setCategoryList] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('tableNumber');
  const [bookings, setBookings] = useState([]);
  const slotBookingsList = useSelector((state) => state.slotBooking.bookingsList);
  const { name, id, storeid } = useParams();
  const bookinglist = useSelector((state) => state.booking.bookingsList);
  useEffect(() => {
    const params = storeid;
    console.log(params);
    dispatch(getTableBooking(params));
  }, [dispatch]);

  useEffect(() => {
    if (bookinglist.length) {
      console.log('RAMO', bookinglist);
      setBookings(bookinglist);
    } else {
      setBookings([]);
    }
  }, [bookinglist, dispatch]);
  // useEffect(() => {
  //   const params = storeid;
  //   dispatch(getSlotBooking(params));
  // }, [dispatch]);

  // useEffect(() => {
  //   if (slotBookingsList.length) {
  //     console.log('RAMO', slotBookingsList);
  //     setBookings(slotBookingsList);
  //   } else {
  //     console.log('first');
  //     setBookings([]);
  //   }
  // }, [slotBookingsList, dispatch]);

  const handleDeleteCategory = (tableId) => {
    try {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this category data!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const updatedTables = tables.filter((table) => table.id !== tableId);

          setTables(updatedTables);

          swal('Poof! Your imaginary file has been deleted!', {
            icon: 'success',
          });
        } else {
          swal('Your imaginary file is safe!');
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const initialTables = [
    {
      id: '13',
      tableNumber: 6,
      seatingCapacity: 2,
    },
    {
      id: '14',
      tableNumber: 1,
      seatingCapacity: 4,
    },
    {
      id: '15',
      tableNumber: 2,
      seatingCapacity: 3,
    },
  ];

  const [editingTable, setEditingTable] = useState(null);

  const handleEditClick = (table) => {
    setEditingTable({ ...table });
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //new changes

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [bookings] = useState([
  //   {
  //     id: 1,
  //     bookedDate: "2023-10-18",
  //     startTime: "09:00",
  //     endTime: "17:00",
  //     tableNumber: 6,
  //     seatingCapacity: 2,
  //     status: "booked",
  //   },
  //   {
  //     id: 2,
  //     bookedDate: "2023-09-15",
  //     startTime: "09:00",
  //     endTime: "17:00",
  //     tableNumber: 12,
  //     seatingCapacity: 8,
  //     status: "cancelled",
  //   },
  //   {
  //     id: 3,
  //     bookedDate: "2023-08-20",
  //     startTime: "10:00",
  //     endTime: "18:00",
  //     tableNumber: 3,
  //     seatingCapacity: 4,
  //     status: "booked",
  //   },
  //   {
  //     id: 4,
  //     bookedDate: "2023-06-05",
  //     startTime: "12:00",
  //     endTime: "16:00",
  //     tableNumber: 8,
  //     seatingCapacity: 6,
  //     status: "cancelled",
  //   },
  // ]);

  const [filter, setFilter] = useState('all');

  const filteredBookings = bookings.filter((booking) => {
    if (filter === 'pastMonth') {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      return new Date(booking.bookingDate) >= oneMonthAgo;
    } else if (filter === 'pastYear') {
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      return new Date(booking.bookingDate) >= oneYearAgo;
    } else if (filter === 'past6Months') {
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      return new Date(booking.bookingDate) >= sixMonthsAgo;
    } else {
      return true; // Show all bookings
    }
  });
  const [page, setPage] = useState(1);

  const filteredBookingsC = bookings.filter((booking) => {
    return booking.status === 'cancelled';
  });
  const itemsPerPage = 6;
  const totalItems = filteredBookings.length;
  const totalItemsC = filteredBookingsC.length;

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };
  // Calculate the range of items to display on the current page
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const cardStyles = {
    width: '80%',
    margin: 'auto',
    padding: '20px',
  };

  const paperStyles = {
    padding: '16px',
    marginBottom: '16px',
    border: '1px solid #3f51b5',
    borderRadius: '4px',
  };

  const buttonStyles = {
    marginRight: '10px',
  };

  function convertTo12HourFormat(time) {
    const [hours, minutes] = time.split(':');
    const hour = hours % 12 || 12;
    const period = hours < 12 ? 'AM' : 'PM';
    return `${hour}:${minutes} ${period}`;
  }

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const monthIndex = dateObject.getMonth(); // Note: Month is zero-based
    const year = dateObject.getFullYear();

    // Array of month abbreviations
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${day}-${monthAbbreviations[monthIndex]}-${year}`;
  };
  return (
    <Page title="Booking History">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          action={
            <>
              <div style={{ display: 'flex', gap: '10px' }}>
                <nav role="navigation">
                  <ul className="unique-nav">
                    <li className="unique-li">
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <Iconify style={{ width: '20px', height: '20px' }} icon={'octicon:move-to-end-16'} />
                        <div className="unique-a font-xl">Move to</div>
                      </div>

                      <ul className="unique-dropdown">
                        <Link
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                          className="unique-a font-sm "
                          to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/addbooking`}
                        >
                          <li
                            className="unique-li"
                            style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                            <Iconify icon={'gridicons:create'} />
                            <h4>Create Booking </h4>{' '}
                          </li>
                        </Link>
                        <Link
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                          className="unique-a font-sm "
                          to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/list`}
                        >
                          <li
                            className="unique-li"
                            style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                            <Iconify icon={'carbon:list'} />
                            <h4> Booking list</h4>{' '}
                          </li>
                        </Link>
                        {/* <Link
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                          className="unique-a font-sm "
                          to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/bookinghistory`}
                        >
                          <li
                            className="unique-li"
                            style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                          <Iconify icon={'mdi:history'} />
                            <h4> Booking History</h4>{' '}
                          </li>
                        </Link> */}
                        <Link
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                          className="unique-a font-sm "
                          to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/addslot`}
                        >
                          <li
                            className="unique-li"
                            style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                            <Iconify icon={'gridicons:create'} />
                            <h4>Create Slot</h4>{' '}
                          </li>
                        </Link>
                        
                        <Link
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                          className="unique-a font-sm "
                          to={`${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/listslotcalendar`}
                        >
                          <li
                            className="unique-li"
                            style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                            <Iconify icon={'carbon:list'} />
                            <h4>List slot</h4>{' '}
                          </li>
                        </Link>
                        <Link
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '10px' }}
                          className="unique-a font-sm "
                          to={`${PATH_DASHBOARD.table.root}/${name}/${storeid}/addtable`}
                        >
                          <li
                            className="unique-li"
                            style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                            <Iconify icon={'gridicons:create'} />
                            <h4>Create Table</h4>
                          </li>
                        </Link>
                        <Link
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', gap: '1px' }}
                          className="unique-a font-sm "
                          to={`${PATH_DASHBOARD.table.root}/${name}/${storeid}/listtable`}
                        >
                          <li
                            className="unique-li"
                            style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                            <Iconify icon={'carbon:list'} />
                            <h4>List Table</h4>{' '}
                          </li>
                        </Link>
                       
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </>
          }
          heading="Booking  History"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Store',
              href: `${PATH_DASHBOARD.TableBooking.root}/${name}/${storeid}/addslot`,
            },
            { name: name },
          ]}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={2}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="All Bookings " {...a11yProps(0)} />
                  <Tab label="Cancelled" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Container>
                  <InputLabel htmlFor="filterSelect" style={{ fontSize: '18px' }}>
                    Filter by
                  </InputLabel>
                  <FormControl>
                    <Select
                      id="filterSelect"
                      style={{ width: '140px', height: '40px' }}
                      value={filter}
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="all">Show All</MenuItem>
                      <MenuItem value="pastMonth">Past Month</MenuItem>
                      <MenuItem value="past6Months">Past 6 Months</MenuItem>
                      <MenuItem value="pastYear">Past Year</MenuItem>
                    </Select>
                  </FormControl>
                  {filteredBookings.length > 0 ? (
  <Grid container spacing={2} padding={4}>
    {Array.from(new Set(filteredBookings.map(booking => `${booking.fromTime}-${booking.toTime}`)))
      .map((timeInterval, index) => {
        const matchingBookings = filteredBookings.filter(booking => {
          return `${booking.fromTime}-${booking.toTime}` === timeInterval;
        });

        // Assuming matchingBookings contains at least one booking for each time interval
        const booking = matchingBookings[0];

        return (
          <Grid item xs={24} md={4} key={index}>
            <Card variant="outlined">
              <CardContent>
                <Typography style={{ whiteSpace: "nowrap",fontSize:"1.2vw",fontWeight:600 }} >
                  Booked Date: {formatDate(booking.bookingDate)}
                </Typography>
                <Typography>Name: {booking.fullName}</Typography>
                <Typography>Email: {booking.email}</Typography>

                <Typography>Booking method: {booking.bookingMethod}</Typography>
                <Typography>
                  Booked Time: {convertTo12HourFormat(booking.fromTime)} to {convertTo12HourFormat(booking.toTime)}
                </Typography>
                <Typography>Status: {booking.status}</Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
  </Grid>
) : (
  <Grid item xs={12} container justifyContent="center">
    <Card variant="outlined" style={{ border: '1px solid #3f51b5' }}>
      <CardContent>
        <Typography align="center" variant="h6">
          Booking History not available
        </Typography>
        <Typography variant="subtitle1">Make some bookings to make it available.</Typography>
      </CardContent>
    </Card>
  </Grid>
)}


                  <Pagination count={Math.ceil(totalItems / itemsPerPage)} page={page} onChange={handlePageChange} />
                </Container>
              </CustomTabPanel>
              <CustomTabPanel value={value} onClick={() => setFilter('cancelled')} index={1}>
                <Container>
                  {filteredBookingsC.length > 0 ? (
                    <Grid container spacing={2} padding={4}>
                      {filteredBookingsC.slice(startIndex, endIndex).map((booking) => (
                        <Grid item xs={4} key={booking.id}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h6" component="div">
                                Booked Date: {booking.bookingDate}
                              </Typography>
                              <Typography>Name: {booking.fullName}</Typography>
                              <Typography>Email: {booking.email}</Typography>
                              <Typography>Booking method: {booking.bookingMethod}</Typography>
                              <Typography>Status: {booking.status}</Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid item xs={12} container justifyContent="center">
                      <Card variant="outlined" style={{ border: '1px solid #3f51b5' }}>
                        <CardContent>
                          <Typography align="center" variant="h6">
                            No cancelled bookings Available
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  <Pagination count={Math.ceil(totalItemsC / itemsPerPage)} page={page} onChange={handlePageChange} />
                </Container>{' '}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Item Three
              </CustomTabPanel>
            </Box>
          </Grid>
        </LocalizationProvider>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------
