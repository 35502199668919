import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// utils
import { axiosAdmin } from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  //   categories: [],
  tables: [],
  // category: '',
  table: '',
  filters: {
    name: [],
  },
  selectedCatId: null,
  count: null,
  pages: null,
  skip: null,
  //   sortBy: null,
  //   filters: {
  //     gender: [],
  //     category: 'All',
  //     colors: [],
  //     priceRange: '',
  //     rating: '',
  //   },
  //   checkout: {
  //     activeStep: 0,
  //     cart: [],
  //     subtotal: 0,
  //     total: 0,
  //     discount: 0,
  //     shipping: 0,
  //     billing: null,
  //   },
};

const slice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    gettablesSuccess(state, action) {
      state.isLoading = false;
      state.tables = action.payload.list;
      state.count = action.payload.count;
      state.pages = action.payload.page;
      state.skip = action.payload.skip;
    },

    deletetablesSuccess(state, action) {
      state.isLoading = false;
      state.tables = action.payload;
    },

    // GET PRODUCT
    gettableSuccess(state, action) {
      state.isLoading = false;
      state.table = action.payload;
    },

    filtertable(state, action) {
      //  state.filters.name = action.payload.name;
      state.tables = action.payload;
    },

    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    // CHECKOUT

    deletetableSuccess(state, action) {
      //     const { id } = action.payload;
      //  state.categories =  state.categories.filter((category) => category.id !== id);
      state.isLoading = false;
      state.tables = action.payload;
    },

    selectTable(state, action) {
      const catId = action.payload;
      state.isOpenModal = true;
      state.selectedCatId = catId;
    },

    createTableSuccess(state, action) {
      const newCategory = action.payload;
      state.isLoading = false;
      state.tables = [...state.tables, newCategory];
    },

    getTablesSuccessAll(state, action) {
      state.isLoading = false;
      state.tables = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { gettableSuccess, selectTable, filtertable, sortByProducts } = slice.actions;

// ----------------------------------------------------------------------

export function getTablesAll(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.get(`/stores-table/store/${params}`);
        dispatch(slice.actions.getTablesSuccessAll(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// export function createRegistrationAdmin(newTable) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       console.log(newTable);
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         const response = await axiosAdmin.post('/store-admin/register', newTable);
//         console.log(response);
//         // dispatch(slice.actions.createTableSuccess(response.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function createRegistrationAdmin(newSlotslot,callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
        console.log(newSlotslot)
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.post('/store-admin/register', newSlotslot);
        console.log(response.data)
        callBack(false);
      // dispatch(slice.actions.createBookingSuccess(response.data));
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function getCategories() {
//   return async () => {

//     alert('Category')
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');

//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);

//       const response = await axiosAdmin.get('/categories');

//       dispatch(slice.actions.getCategoriesSuccess(response.data));
//       }

//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getTables(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get('/categories?', {
          params,
        });

        dispatch(slice.actions.gettablesSuccess(response.data));
        // alert(JSON.stringify(response.data))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTable(id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get(`/categories/${id}`);
        dispatch(slice.actions.getCategorySuccess(response.data));
        // alert(JSON.stringify(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error));
      // console.error('error' +JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateTables(id, updateCategory) {
  console.log(updateCategory);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.put(`/stores-table/${id}`, updateCategory);
        dispatch(slice.actions.getCategorySuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

// export function deleteTables(categoryID) {
//   // alert(categoryID)
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');
//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);
//         const response = await axiosAdmin.delete(`/stores-table/${categoryID}`);
//         // dispatch(slice.actions.deletetablesSuccess(response.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function deleteTables(newSlotslot, callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(newSlotslot);
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.delete(`/stores-table/${newSlotslot}`);
        callBack(false);
        // dispatch(slice.actions.createSlotSlotSuccess(response.data));
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteManyTables(newSlotslot, callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(newSlotslot);
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.post('/stores-table/remove-many', newSlotslot);
        callBack(false);
        // dispatch(slice.actions.createSlotSlotSuccess(response.data));
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
