import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable */
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// utils
import { axiosAdmin } from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
import { number } from 'prop-types';
import appendMultiArray from 'src/utils/appendMultiArray';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  banners: [],
  categoryItem: {},
  stores: [],
  store: {},
  filters: {
    name: [],
  },
  selectedCatId: null,
  count: null,
  pages: null,
  skip: null, //   sortBy: null, //   filters: { //     gender: [], //     store: 'All', //     colors: [], //     priceRange: '', //     rating: '', //   }, //   checkout: { //     activeStep: 0, //     cart: [], //     subtotal: 0, //     total: 0, //     discount: 0, //     shipping: 0, //     billing: null, //   },
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    }, // HAS ERROR

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }, // GET PRODUCTS

    getstoresSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload.list;
      state.count = action.payload.count;
      state.pages = action.payload.page;
      state.skip = action.payload.skip;
    },

    deletestoresSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    }, // GET PRODUCT

    getstoreSuccess(state, action) {
      state.isLoading = false;
      state.store = action.payload;
      state.categoryItem = action.payload.categories;
    },

    filterstore(state, action) {
      //  state.filters.name = action.payload.name;
      state.stores = action.payload;
    },

    sortByProducts(state, action) {
      state.sortBy = action.payload;
    }, // CHECKOUT

    deleteImageSuccess(state, action) {
      const { id } = action.payload;
      state.banners = state.banners.filter((img) => store.id !== id);
    },

    deletestoreSuccess(state, action) {
      const { id } = action.payload;

      state.stores = state.stores.filter((store) => store.id !== id);
    },

    selectstore(state, action) {
      const catId = action.payload;
      state.isOpenModal = true;
      state.selectedCatId = catId;
    },

    createStoreSuccess(state, action) {
      const newStore = action.payload;
      state.isLoading = false;
      state.stores = [...state.stores, newStore];
    },

    updateStoreSuccess(state, action) {
      const newStore = action.payload;
      state.isLoading = false;
      state.stores = [...state.stores, newStore];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getstoresSuccess, selectstore, filterstore, sortByProducts } = slice.actions;

// ----------------------------------------------------------------------

export function createStore(newStore, callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        let storeArray = new FormData();
        const images = newStore?.banners;

        for (let i = 0; i < images.length; i++) {
          storeArray.append('banners', images[i]);
        }

        storeArray.append('tags', JSON.stringify(newStore?.tags));
        storeArray.append('contacts', JSON.stringify(newStore?.contacts));
        storeArray.append('features', JSON.stringify(newStore?.features));
        storeArray.append('socialLinks', JSON.stringify(newStore?.socialLinks));
        storeArray.append('workingHours', JSON.stringify(newStore?.workingHours));
        storeArray.append('name', newStore?.name);
        storeArray.append('description', newStore?.description);
        storeArray.append('image', newStore?.logo);
        storeArray.append('categoryId', newStore?.categoryId);
        storeArray.append('location', newStore?.location);
        storeArray.append('latitude', newStore?.latitude);
        storeArray.append('whatsappNumber', newStore?.whatsappNumber);
        newStore?.options && storeArray.append('option', newStore?.options);
        storeArray.append('longitude', newStore?.longitude);
        storeArray.append('offerPIN', newStore?.offerPIN);
        storeArray.append('primaryEmail', newStore?.primaryEmail);

        const response = await axiosAdmin.post('/stores', storeArray, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        callBack(false);
        dispatch(slice.actions.createStoreSuccess(response.data));
      }
    } catch (error) {
      // console.log('error::::::::: ', error);
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getstores() {
//   return async () => {

//     alert('store')
//     dispatch(slice.actions.startLoading());
//     try {
//       const accessToken = window.localStorage.getItem('accessToken');

//       if (accessToken && isValidToken(accessToken)) {
//         setSession(accessToken);

//       const response = await axiosAdmin.get('/stores');

//       dispatch(slice.actions.getstoresSuccess(response.data));
//       }

//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getStores(params) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken'); // alert(JSON.stringify(params))

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get('/stores', {
          params,
        });

        dispatch(slice.actions.getstoresSuccess(response.data)); // alert(JSON.stringify(response.data))
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStore(id) {
  return async () => {
    console.log(id)
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.get(`/stores/${id}`);
        dispatch(slice.actions.getstoreSuccess(response.data));
        return response; // Return the response to the caller

         // alert(JSON.stringify(response.data));
      }
    } catch (error) {
      //alert(JSON.stringify(error));
      //  console.error('error' +JSON.stringify(error));
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateStore(id, updatestore, callBack) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        let storeArray = new FormData();
        const images = updatestore?.banners;

        for (let i = 0; i < images.length; i++) {
          storeArray.append('banners', images[i]);
        }

        storeArray.append('tags', JSON.stringify(updatestore?.tags));
        storeArray.append('contacts', JSON.stringify(updatestore?.contacts));
        storeArray.append('features', JSON.stringify(updatestore?.features));
        storeArray.append('socialLinks', JSON.stringify(updatestore?.socialLinks));
        storeArray.append('workingHours', JSON.stringify(updatestore?.workingHours));
        storeArray.append('name', updatestore?.name);
        storeArray.append('description', updatestore?.description);
        storeArray.append('image', updatestore?.logo);
        storeArray.append('categoryId', updatestore?.categoryId);
        storeArray.append('location', updatestore?.location);
        storeArray.append('latitude', updatestore?.latitude);
        storeArray.append('whatsappNumber', updatestore?.whatsappNumber);
        storeArray.append('option', updatestore?.options);
        storeArray.append('longitude', updatestore?.longitude);
        storeArray.append('offerPIN', updatestore?.offerPIN);
        storeArray.append('primaryEmail', updatestore?.primaryEmail);

        const response = await axiosAdmin.put(`/stores/${id}`, storeArray, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        callBack(false);

        dispatch(slice.actions.updateStoreSuccess(response.data));
      }
    } catch (error) {
      callBack(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteStore(storeID) {
  // alert(storeID)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.delete(`/stores/${storeID}`);
        dispatch(slice.actions.deletestoresSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function changeVisibility(storeID,status) {
  const data={
    status:status
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const response = await axiosAdmin.patch(`/stores/${storeID}/status`,data);
        dispatch(slice.actions.deletestoresSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deleteStoreImage(imgId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosAdmin.delete(`/stores/remove-image/${imgId}`);

        dispatch(slice.actions.deleteImageSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteManyStore(storeId) {
  // alert(offerId)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.post('/stores/remove-many', storeId);

      dispatch(slice.actions.deletestoreSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function UpdatePlaceIdData(storeId,callBack) {
  console.log(storeId)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAdmin.post(`/stores/update-place-id/${storeId}`);
      console.log(response)
      callBack(false)
    } catch (error) {
      callBack(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}