import axios from 'axios';
// config
import { HOST_API,CATEGORY_API,OFFER_API, ADMIN_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);


const axiosAdmin =  axios.create({
  baseURL:ADMIN_API,
   
});

axiosAdmin.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

const axiosOffer =  axios.create({
  baseURL:OFFER_API
});

axiosOffer.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export {
  axiosInstance,
  axiosAdmin,
  axiosOffer
};
