import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 200, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
      <g transform="translate(0.000000,354.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M301 2057 c-129 -754 -236 -1378 -239 -1384 -3 -10 45 -13 221 -13
l225 0 6 27 c6 30 96 591 96 598 0 8 430 5 430 -2 1 -5 22 -145 49 -313 l47
-305 227 -3 227 -2 -5 22 c-2 13 -106 628 -230 1368 -124 740 -228 1353 -231
1363 -5 16 -28 17 -297 17 l-292 0 -234 -1373z m599 182 c38 -272 70 -503 70
-512 0 -15 -16 -17 -146 -17 l-147 0 5 33 c3 17 36 249 74 514 38 265 70 481
71 479 1 -1 34 -225 73 -497z"/>
<path d="M1930 2045 l0 -1385 575 0 575 0 0 210 0 210 -360 0 -360 0 0 1175 0
1175 -215 0 -215 0 0 -1385z"/>
<path d="M3990 2565 l0 -865 103 -49 c100 -49 222 -94 292 -107 l35 -6 0 146
0 146 250 0 250 0 0 -585 0 -585 215 0 215 0 0 1124 0 1124 -27 6 c-31 6 -196
22 -320 31 l-83 5 0 -345 0 -345 -250 0 -250 0 0 585 0 585 -215 0 -215 0 0
-865z"/>
<path d="M4920 3252 l0 -178 123 -12 c67 -7 164 -21 215 -31 l92 -18 0 209 0
208 -215 0 -215 0 0 -178z"/>
<path d="M5710 3168 l0 -263 108 -52 c59 -29 98 -51 87 -48 -11 2 -59 13 -107
24 l-88 19 0 -699 0 -699 58 0 c56 0 296 25 340 35 l22 5 0 970 0 970 -210 0
-210 0 0 -262z"/>
<path d="M6598 2338 c-3 -1235 3 -1140 -84 -1216 -91 -81 -222 -80 -306 2
l-33 33 -85 -24 c-47 -13 -141 -35 -210 -49 -69 -14 -127 -28 -130 -30 -2 -3
11 -35 29 -72 50 -97 191 -235 291 -284 112 -55 192 -71 335 -65 139 5 217 26
314 85 92 56 163 122 219 206 57 85 88 169 102 276 5 44 10 515 10 1153 l0
1077 -225 0 -224 0 -3 -1092z"/>
<path d="M7410 2720 l0 -712 158 82 c86 45 208 110 270 144 l113 61 -40 3 -41
3 0 350 0 349 108 0 c137 0 189 -17 252 -80 57 -56 80 -116 87 -230 6 -94 -7
-176 -37 -236 -11 -21 -20 -39 -20 -41 0 -1 19 -11 43 -21 60 -27 92 -64 136
-156 21 -45 45 -90 53 -100 15 -19 16 -19 51 3 90 55 176 190 214 331 25 96
23 317 -5 431 -37 153 -113 288 -205 362 -57 47 -177 110 -262 138 -68 22 -84
23 -472 27 l-403 3 0 -711z"/>
<path d="M8224 2113 c-27 -21 -107 -77 -179 -125 l-129 -88 59 0 c233 0 334
-82 372 -300 37 -211 -24 -397 -155 -476 -61 -37 -95 -44 -224 -44 l-98 0 -2
395 -3 394 -95 -56 c-52 -31 -155 -87 -227 -125 l-133 -70 0 -479 0 -479 426
0 c474 0 489 2 621 67 178 89 292 237 345 450 18 72 22 117 22 253 1 101 -4
187 -12 223 -13 57 -14 57 -50 57 -45 0 -109 29 -139 61 -12 13 -44 68 -71
121 -61 120 -114 190 -171 229 -61 40 -96 38 -157 -8z"/>
<path d="M3990 1029 l0 -369 215 0 215 0 0 258 0 258 -37 13 c-73 26 -219 100
-305 155 l-88 55 0 -370z"/>
<path d="M5566 776 c-57 -21 -105 -65 -139 -125 -40 -71 -42 -161 -4 -244 50
-109 199 -297 236 -297 10 0 47 30 82 68 200 207 242 390 121 524 -20 22 -54
49 -76 60 -53 27 -165 34 -220 14z m153 -100 c33 -17 51 -54 51 -102 0 -54
-53 -104 -110 -104 -34 0 -48 6 -76 34 -28 28 -34 42 -34 76 0 34 6 48 34 76
38 38 85 45 135 20z"/>
</g>
</svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
